import React from "react";

const SVGHorizLine = () => {
    return(
        <svg width="100%" height="100%" viewBox="0 0 100% 100%">
            <line class="point" x1="0" x2="100%" y1="50%" y2="50%" />
        </svg>
    );
};

export default SVGHorizLine;